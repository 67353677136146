import React from 'react'
import Layout from "../components/App/Layout"
import NotFound from '../components/NotFound/index'
import SEO from '../components/App/SEO';

const Team = () => {
    return (
        <Layout>
          <SEO
            title="404"
            desc="ページが存在しません。"
            pathname={'/404'}
            type={'Article'}
            shortName="404"
            createdAt="2021-03-02"
            updatedAt="2021-03-02"
            noIndex={true}
            noFollow={true}
          />
          <NotFound />
        </Layout>
    );
};

export default Team