import React from 'react';
import {Link} from 'gatsby'
import Image from '../../assets/images/not-found/rob.png';
import FourZeroFourStyleWrapper from './index.style';

class FourZeroFour extends React.Component {
  render() {
    return (
        <FourZeroFourStyleWrapper className="iso404Page">
          <div className="iso404Content">
            <h2>
              ページが見つかりません
            </h2>
            <p>
              探しているページは存在しないか、移動されました。
            </p>
            <Link
              to="/"
              className="default-btn"
            >
              <i className="flaticon-right"></i> トップページへ<span></span>
            </Link>
          </div>
          <div className="iso404Artwork">
            <img alt="#" src={Image}/>
          </div>
        </FourZeroFourStyleWrapper>
    );
  }
}

export default FourZeroFour;
